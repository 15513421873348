import React from 'react';
import {
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Layout from '../components/layout';
import ImageCard from '../components/image-card';
import PropTypes from 'prop-types';
import CardCombo from '../components/card-combo';
import { PROMO_ID_MAX } from '../constants';

const styling = (theme) => ({
  ytContainer: {
    padding: 16,
  },
  ytVideo: {
    margin: 'auto',
    maxWidth: 650,
    minHeight: 350,
    [theme.breakpoints.up('lg')]: {
      maxWidth: 1000,
      minHeight: 600,
    },
  },
  pageContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    margin: '10px',
    border: '2px solid black',
    borderRadius: 10,
  },
  comboHeader: {
    paddingTop: 16,
    margin: 'auto',
    textAlign: 'center',
    color: props => props.secondary,
  },
  contentGrid: {
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
    padding: 16,
    [theme.breakpoints.up('md')]: {
      maxWidth: 1200,
    },
  },
  crabImg: {
    height: '100%',
    width: '100%',
  },
  crabImgContainer: {
    maxWidth: 400,
    padding: 16,
    [theme.breakpoints.up('md')]: {
      maxWidth: '100%',
    },
  },
  description: {
    margin: 'auto',
    maxWidth: 600,
    padding: 16,
  },
  header: {
    paddingTop: 16,
    textAlign: 'center',
    color: props => props.secondary,
  }
});

const CrabPageTemplate = ({ pageContext }) => {
  const { crabData, cardCombos } = pageContext
  
  // Styling/Theming
  const theme = useTheme();
  const classes = makeStyles(styling)(crabData.color_palette);
  const isDesktop = useMediaQuery(theme.breakpoints.up('md')); // md = 960px

  const actualCardCombos = cardCombos.filter(combo => {
    for (const card of combo.cards) {
      if (card.jsonId > PROMO_ID_MAX) return false;
    }
    return true;
  });
  
  return (
    <Layout title={crabData.fullname}>
      <Grid container direction='column' className={classes.contentGrid}>
        <Grid item>
          <Typography className={classes.header} variant="h1">{crabData.fullname}</Typography>
        </Grid>
        <Grid item container direction={isDesktop ? 'row' : 'column'} justifyContent='center' alignItems='center'>
          <Grid item xs={12} md={6}>
            <ImageCard
              classes={{
                root: classes.crabImgContainer,
                image: classes.crabImg,
              }}
              src={crabData.publicCardImageURL}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography className={classes.description} variant='body2'>
              {crabData.description}
            </Typography>
          </Grid>
        </Grid>

        {actualCardCombos.length > 0 &&
          <Grid item container xs={12} className={classes.combosContainer} justifyContent='center'>
            <Grid item>
              <Typography className={classes.comboHeader} variant="h3">
                Common Combos
              </Typography>
            </Grid>
            <Grid item container direction='row' spacing={1} justifyContent='center'>
              {actualCardCombos.map(combo => (
                <Grid item key={combo.id} xs={12} md={6}>
                  <CardCombo combo={combo} colorPalette={crabData.color_palette} />
                </Grid>
              ))}
            </Grid>
          </Grid>
        }
      </Grid>
    </Layout>
  );
}

CrabPageTemplate.propTypes = {
  pageContext: PropTypes.any.isRequired,
}

export default CrabPageTemplate
