import { 
  Box, 
  Container, 
  Card, 
  CardContent, 
  CardMedia, 
  Grid, 
  Typography, 
  CardHeader, 
  Divider, 
  useMediaQuery, 
  useTheme 
} from '@material-ui/core';
import React from 'react';
import AddIcon from '@material-ui/icons/Add'
import ImageCard from './image-card';
import { Link } from 'gatsby';
import { makeStyles } from '@material-ui/styles';

const styling = {
  comboBox: {
    border: '3px solid',
    borderRadius: 16,
    borderColor: props => props.colorPalette.primary,
    paddingBottom: 16,
  },
  comboHeader: {
    color: props => props.colorPalette.secondary,
  }
};

const ComboCard = ({ card }) => {
  return (
    <Grid container item direction='column'>
      <Link to={`/games/crabs/${card.name}`}>
        <ImageCard src={card.publicCardImageURL} />
      </Link>
    </Grid>
  )
}

const CardCombo = ({ combo, colorPalette }) => {
  const classes = makeStyles(styling)({colorPalette});
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md')); // md = 960px
  
  return (
    <Card className={classes.comboBox}>
      <CardHeader
        title={combo.name}
        align='center'
        className={classes.comboHeader}
      />
      <Grid container direction="row" alignItems='center' justifyContent='center'>
        <Grid item xs={5}>
          <ComboCard card={combo.cards[0]} />
        </Grid>
        <Grid item><AddIcon fontSize="large" /></Grid>
        <Grid item xs={5}>
          <ComboCard card={combo.cards[1]} />
        </Grid>
      </Grid>
    </Card>
  );
}

export default CardCombo;
